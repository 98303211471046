@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
body {margin: 0;padding: 0;}
.banner_sec {background: url(../../assets/images/about-banner-img.png) no-repeat;height: 283px;width: 100%;background-size: cover;}
.banner_text {display: flex;align-items: center;justify-content: center;min-height: 283px;height: 100%;}
.banner_text h1 {text-align: center;font-family: "Urbanist", sans-serif;font-size: 47px;font-weight: 800;line-height: 1.1;color: #FFFFFF;}
.css_online p {font-size: 18px;font-weight: 600;font-family: "Urbanist", sans-serif;display: inline;padding-bottom: 5px;line-height: 1.5;}
.css_online{text-align: center;padding-bottom: 50px;border-bottom: 1px solid #0000001f;}
.about_cs_sec {padding: 50px 0;}
.providing_online_pro {font-size: 18px;font-family: "Urbanist", serif;color: #000;font-weight: 600;}
.provide_various {color: #434343;font-size: 17px;font-weight: 500;font-family: "Urbanist", serif;}
.css_online_image img{width: 100%;}
.css_online_image img {width: 100%;height: 293px;object-fit: cover;border-radius: 10px;}
.ab_he h2{text-align: center;font-family: "Urbanist", sans-serif;font-size: 47px;font-weight: 800;line-height: 1.1;     margin-bottom: 0;}
.ab_he h2 span{color:#EE3124;}
.school_helps {height: 100%;display: flex;align-items: center;}
.counter h2{text-align: center;font-family: "Urbanist", sans-serif;font-size: 47px;font-weight: 800;line-height: 1.1;margin-bottom: 0;}
.counter a {background: #2D2926;color: #fff;border-radius: 10px;text-decoration: none;font-family: "Urbanist", sans-serif;font-size: 16px;font-weight: 600;margin-bottom: -25px;margin-top: 16px;max-width: 230px;height: 44px;width: 100%;display: flex;align-items: center;justify-content: center;}
.counter {text-align: center;box-shadow: 0px 0px 40px 0px #744f4f1a;height: 200px;border-radius: 10px;display: flex;align-items: center;justify-content: center;flex-direction: column;}
.our_vision_img{position: relative;}
.our_vision_img .icon_dots{position: absolute;right: 53px;top: 0;z-index: -1;}
.our_vision_img img {width: 90%;}
.icon_dots img {width: 100%;height: 214px;}
.our_vision_text h2{font-family: "Urbanist", sans-serif;font-size: 47px;font-weight: 800;line-height: 1.1;margin-bottom: 20px;}
.our_vision_text h2 span{color:#EE3124;   }
.our_vision_text p{color: #434343;font-size: 17px;font-weight: 500;font-family: "Urbanist", serif;}
.coun_sec{padding:20px 0 70px 0;}
.vision_border{border-bottom: 1px solid #0000001f;padding-bottom: 50px;}
.about_contant{padding-bottom: 50px;}
/* privacy css start here*/
.privacy_policy{padding: 50px 0;}
.privacy h6 {color: #172b50;font-size: 20px;font-family: "Urbanist", sans-serif;font-weight: 800;}
.privacy p{font-size: 17px;font-family: "Urbanist", sans-serif;font-weight: 500;color: #172b4d;}
.privacy li{font-size: 16px;font-family: "Urbanist", sans-serif;font-weight: 500;color: #172b4d;line-height: 32px;}
.privacy p a{text-decoration: none;}

@media (max-width: 575px) {
    .banner_text {min-height: auto;padding: 30px 0;}
    .banner_text h1 {font-size: 30px;}
    .banner_sec {height: auto;}
    .ab_he h2 {font-size: 30px;margin-bottom: 9px;}
    .css_online p , .providing_online_pro{font-size: 17px;}
    .counter {margin-bottom: 30px;}
    .our_vision_text p {font-size: 16px;}
    .our_vision_text h2 {font-size: 30px;}
    .counter h2 {font-size: 30px;}
}
@media (max-width: 425px) {
    .banner_text {padding: 18px 0;}
}