/****************courses-details css****************/

.title-hd{
  color: #2D2926;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}
.sim-cours-hd a{
  color: #2D2926;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; 
  padding: 0px 0px 0px 15px;
  }
.sim-course-dt{
  color: #434343;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 0px 0px 0px 15px
}
.courses-detail-page {
    overflow: hidden;
}
.courses-detail-ban-sec:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 40%);
}
.courses-detail-ban-img img {
    width: 100%;
    height: 100%;
}
.courses-detail-ban-content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.courses-detail-ban-content .lg-heading {
    color: #fff;
    padding: 0 0 31px 0;
}
.breadcrumb-hd a {
    color: #FFF;
    text-align: center;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0px 5px 0 5px;
}
.breadcrumb-hd {
    text-align: center;
    color: #fff;
}

/*********courses-tab**********************/
.tab-panel-sec {
    padding: 65px 0px 0px;
}
.tab-box {
    border-bottom: 1px solid rgba(45, 41, 38, 0.10);
}
.tabcon {
  display: none;
   padding: 28px 0px 5px;
}
.tab-course {
    border-bottom: 2px solid #2D2926;
}
.tab-course button {
    border-radius: 5px 5px 0px 0px;
    outline: none;
    color: #000;
    cursor: pointer;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    background: transparent;
    border: 0;
}
.tab-course button:hover {
  background-color: #fff;
}
.tab-course button.active {
    background-color: #2D2926;
    color: #fff !important;
    position: relative;
}
.tablinks.active:before {content: "";position: absolute;border-top: 10px solid #000;border-left: 10px solid transparent;border-right: 10px solid transparent;left: 50%;bottom: -10px;z-index: 999;transform: translateX(-50%);}
.tab-course button {padding: 3px 15px;}



/************sidebar sec**************/

.lists-courses-sec {
   padding: 20px 0px 0px;
}

.strategy-list.list-course ul li a {
    color: #2D2926;
    font-family: Urbanist;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
}
.course-detail {
    display: flex;
    padding: 5px 0px 47px;
}
.strategy-list.list-course {
    width: 42%;
}
.center-content {
    display: flex;
}
.middle-box {
    width: calc(100% - 340px);
}
.sidebar-courses-sec {
    max-width: 340px;
    margin: 65px 0px 0px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
    height: 100%;
}
.center-content {
    display: flex;
    justify-content: space-evenly;
}
.sim-courses-list {
    display: flex;
}
.sim-top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.trial-courses .strategy-list.list-course {
    width: 100%;
}
.comprehensive-course .sim-courses-list {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}
.sim-courses-list .img-lf {
    width: 100%;
    max-width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.10);
}
.sim-courses-list .img-lf img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.search-box {
    padding: 0px 0px 10px 0px;
}

.sim-courses {
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    padding: 20px 0px 20px 0px;
}

.trial-courses {
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    padding: 20px 0px 20px 0px;
}

.comprehensive-course {
    padding: 20px 0px 0px 0px;
}

.view-courses a {
    color: #434343;
    text-align: right;
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px; /* 141.667% */
    text-decoration-line: underline;
}

.comprehensive-course .title-hd {
    padding: 0 0 10px 0;
}

.rocketdetail-icon {
    position: absolute;
    right: -50%;
    bottom: 23%;
  animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}
.stardetail-icon {
    position: absolute;
    top: 0;
    right: -32%;
    animation-name: twinkle;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}


/*******************************************************/

@media(max-width:767px) {

.courses-detail-page .lg-heading {
    padding: 0 0 15px 0;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
}
.center-content {
   flex-direction: column-reverse;
}
.middle-box {
    width: 100%;
}
.course-detail {
   flex-direction: column;
    padding: 5px 0px 47px;
}
.strategy-list.list-course {
    width: 100%;
}

.sidebar-courses-sec {
    margin: 65px auto;
    width: 100%;
}
.tab-panel-sec {
    padding: 0px 0px 0px;
}
.courses-detail-ban-img img {
    width: 100%;
    height: 100%;
    min-height: 220px;
}
}
/***************/

 @media (max-width:499px) {

.courses-detail-ban-content .lg-heading {
    font-size: 21px;
    /* padding: 0; */
    line-height: 30px;
    text-align: center;
}
  .tab-course button {
    padding: 2px 6px;
}
.tab-course button {
    font-size: 13px;
}
.text-para p {
    font-size: 14px;
}
 }
/***************/

@media(min-width:768px) and (max-width:991px) {
.courses-detail-ban-content .lg-heading {
    color: #fff;
     padding: 0;
}
.courses-detail-ban-content .lg-heading {
    font-size: 31px;
}

.center-content {
   flex-direction: column-reverse;
}
.middle-box {
    width: 100%;
}
.course-detail {
   flex-direction: column;
    padding: 5px 0px 47px;
}
.strategy-list.list-course {
    width: 100%;
}

.sidebar-courses-sec {
    margin: 65px auto;
    width: 100%;
}
}



/***************/

@media(min-width:992px) and (max-width:1199px) {
.courses-detail-ban-content .lg-heading {
    font-size: 31px;
}
.stardetail-icon {
    top: -69px;
    right: -14%;
}
}

/***************/

@media  screen and (min-width: 1400px) {

}












