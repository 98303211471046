/****************history-page css****************/
.history-page {
  overflow: hidden;
}
.history-ban-sec:before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 40%);
}
.history-ban-img img {
  width: 100%;
  height: 100%;
}
.history-ban-content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.history-ban-content .lg-heading {
  color: #fff;
  padding: 0 0 31px 0;
}

/***************message sec******************/
.message-sec {
  padding: 96px 0px 0px;
}

.message-con-lf .lg-heading {
  padding: 0 0 27px 0;
}

.message-inner {
  display: flex;
  flex-wrap: wrap;
}
.message-con-lf {
  width: 100%;
  flex-basis: 100%;
  max-width: 60%;
  padding: 0px 40px 0px 0px;
}
.message-con-rt {
  width: 100%;
  flex-basis: 100%;
  max-width: 40%;
}

.message-con-rt-img {
  max-width: 440px;
  width: 100%;
  height: 440px;
  border-radius: 500px 50px 500px 500px;
  position: relative;
}
.message-con-rt-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 500px 50px 500px 500px;
  position: relative;
}
.message-con-rt-img:before {
  position: absolute;
  content: url(../../assets/images/dots.png);
  top: 65%;
  left: 68%;
}
.designation,
.name-hd {
  color: #434343;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}

/********timeline sec*******/

.timeline-sec {
  padding: 150px 0px 80px;
}
.timeline-con {
  text-align: center;
}
.timeline-con .lg-heading {
  padding: 0 0 20px 0;
}
.timeline-con .text-para p {
  max-width: 987px;
  margin: 0 auto;
}
.planet-icon {
  position: absolute;
  top: 0;
  right: 0;
  animation: Shake 2s linear infinite;
}

@keyframes Shake {
  0% {
    transform: rotate(5deg);
  }

  25% {
    transform: rotate(-6deg);
  }

  50% {
    transform: rotate(5deg);
  }

  75% {
    transform: rotate(-6deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

/*******************************/

.hist-owl-carausel-sec {
  /* padding: 80px 0px; */
  border-top: 1px solid #000;
}
.slide-hd {
  color: #ee3124;
  text-align: center;
  font-family: Urbanist;
  font-size: 21px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  padding: 0 0 7px 0;
}

.slide-para {
  color: #434343;
  text-align: center;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.cs-slide-timeline {
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  min-height: 200px;
  margin: 60px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 40px 13px 40px;
}
/* .hist-owl-carausel-inner button.owl-next span {
  font-size: 0;
}
.hist-owl-carausel-inner button.owl-prev span {
  font-size: 0;
} */
.hist-owl-carausel-inner button.owl-prev span {
  font-size: 36px;
  border-radius: 40px;
  border: 1px solid;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 97px;
  padding-bottom: 8px;
  color: #2d2926;
  position: absolute;
  top: -4px;
}
.hist-owl-carausel-inner button.owl-next span {
  font-size: 36px;
  border-radius: 40px;
  border: 1px solid;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 97px;
  padding-bottom: 8px;
  color: #2d2926;
  position: absolute;
  top: -4px;
}

.hist-owl-carausel-inner .owl-nav {
  position: relative;
  top: -169px;
}

.hist-owl-carausel-inner .owl-carousel button.owl-dot {
  padding: 4px !important;
  font: inherit;
  color: #000;
  border: 5px solid #fff;
  background: #ef4135;
  border-radius: 50px;
  outline-style: inset;
  margin: 0px 25px 0px 0px;
  box-shadow: 1px 3px 9px 4px rgb(0 0 0 / 42%);
}
.hist-owl-carausel-inner .owl-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -15px;
  left: 23%;
}
.hist-owl-carausel-inner button.owl-dot.active {
  border: 9px solid #ef4135;
  background: #fff;
  border-radius: 50px;
  margin: 0px 25px 0px 0px;
  color: #fff !important;
  outline-style: none;
  padding: 7px !important;
}

.rocket-iconhist {
  position: absolute;
  top: 88px;
  left: 0;
  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/*********************************************/

@media (max-width: 1199px) {
  .hist-owl-carausel-inner button.owl-prev span {
    left: 0;
  }
  .hist-owl-carausel-inner button.owl-next span {
    right: 0;
  }
}
/*****/

@media (max-width: 991px) {
  .hist-owl-carausel-inner button.owl-prev span {
    left: -50px;
  }
  .hist-owl-carausel-inner button.owl-next span {
    right: -50px;
  }
}

/******/

@media (max-width: 767px) {
  .message-sec {
    padding: 55px 0px 25px;
  }
  .history-page .lg-heading {
    padding: 0 0 15px 0;
    font-size: 30px;
    line-height: 40px;
  }
  .message-con-lf,
  .message-con-rt {
    max-width: 100%;
  }
  .message-con-lf {
    padding: 0px 0px 70px;
  }
  .message-con-rt {
    padding: 0px 15px 80px;
  }
  .message-con-rt-img img {
    object-fit: cover;
  }
}

/*******/

@media (max-width: 620px) {
  .hist-owl-carausel-inner button.owl-prev span {
    left: -37px;
  }
  .hist-owl-carausel-inner button.owl-next span {
    right: -37px;
  }
}

/*******/

/*******/

@media (max-width: 580px) {
  .hist-owl-carausel-inner button.owl-prev span {
    left: -32px;
  }
  .hist-owl-carausel-inner button.owl-next span {
    right: -32px;
  }
}
/*******/

@media (max-width: 579px) {
  .hist-owl-carausel-inner button.owl-prev span {
    left: 0px;
  }
  .hist-owl-carausel-inner button.owl-next span {
    right: 0px;
  }
}

/*******/

@media (max-width: 494px) {
  .hist-owl-carausel-inner button.owl-next span {
    top: -34px;
  }
  .hist-owl-carausel-inner button.owl-prev span {
    top: -34px;
  }
}

/*******/

@media (max-width: 375px) {
  .hist-owl-carausel-inner button.owl-next span {
    top: -71px;
  }
  .hist-owl-carausel-inner button.owl-prev span {
    top: -71px;
  }
}
/*******/

@media (max-width: 499px) {
  .history-ban-img img {
    width: 100%;
    height: 100%;
    min-height: 115px;
  }

  .history-ban-content .lg-heading {
    font-size: 26px;
    padding: 0;
  }
  .message-con-rt-img:before {
    top: 56%;
    left: 52%;
  }
}

/*******/
@media (max-width: 374px) {
  .hist-owl-carausel-inner .owl-dots {
    left: 9%;
  }
}

/*******/

@media (min-width: 375px) and (max-width: 380px) {
  .hist-owl-carausel-inner .owl-dots {
    left: 3%;
  }
}
/*******/

@media (min-width: 381px) and (max-width: 399px) {
  .hist-owl-carausel-inner .owl-dots {
    left: 5%;
  }
}

/*******/

@media (min-width: 400px) and (max-width: 424px) {
  .hist-owl-carausel-inner .owl-dots {
    left: 8%;
  }
}
/*****/

@media (min-width: 425px) and (max-width: 449px) {
  .hist-owl-carausel-inner .owl-dots {
    left: 9%;
  }
  .rocket-iconhist {
    top: 30px;
  }
}
/*****/

@media (min-width: 450px) and (max-width: 499px) {
  .hist-owl-carausel-inner .owl-dots {
    left: 14%;
  }
}

/******/

@media (min-width: 500px) and (max-width: 689px) {
  .hist-owl-carausel-inner .owl-dots {
    left: 88px;
  }
}

/******/

@media (min-width: 690px) and (max-width: 767px) {
  .hist-owl-carausel-inner .owl-dots {
    left: -77px;
  }
}
/*******/

@media (min-width: 768px) and (max-width: 991px) {
  .message-con-rt-img img {
    object-fit: contain;
  }
  .hist-owl-carausel-inner .owl-dots {
    left: 2%;
  }

  .hist-owl-carausel-inner button.owl-prev span {
    left: -34px;
  }
  .hist-owl-carausel-inner button.owl-next span {
    right: -34px;
  }
  .rocket-iconhist {
    left: 11px;
  }
}
/*******/

@media (min-width: 992px) and (max-width: 1199px) {
  .message-con-rt-img img {
    height: auto;
  }
  .hist-owl-carausel-inner .owl-dots {
    left: 13%;
  }
}

/*******/
@media (min-width: 1200px) and (max-width: 1399px) {
  .hist-owl-carausel-inner .owl-dots {
    left: 19%;
  }
}
/*******/
