/****************CS Online*******************/
body {
  font-family: Urbanist;
  color: #989898;
  font-size: 14px;
  line-height: 23px;
}
h1,h2,h3,h4,h5,h6{font-family: Urbanist;}
a{font-family: Raleway;}
section {
  position: relative;
}
.xl-heading {
  font-size: 68px;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 80px;
}
.lg-heading {
  font-size: 47px;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 59px;
  color: #2d2926;
}
/* .md-heading {font-size: 21px;font-family:Urbanist;font-weight:800;line-height: 36px;} */

.xs-heading {
  font-size: 18px;
  font-family: Raleway;
  font-weight: 700;
  line-height: 21.13px;
  color: #ee3124;
}
.text-para p {
  font-size: 15px;
  font-family: Raleway;
  line-height: 27px;
  font-weight: 400;
  color: #434343;
}

ul {
  margin: 0px !important;
  padding: 0px !important;
}

.footer-list ul {
  margin: 0px;
  padding: 0px;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
}
.sap-red-btn button,
.sap-red-btn a,
.sap-black-btn button,
.sap-black-btn a,
.sap-light-btn button,
.sap-light-btn a {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  min-height: 45px;
  border-radius: 58px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;
  font-family: Urbanist;
  max-width: 200px;
}

.sap-light-btn button,
.sap-light-btn a {
  background: #fff;
  color: #2d2926;
  box-shadow: 4px 4px 8px 0px rgba(66, 83, 107, 0.09),
    -4px -4px 16px 0px rgba(255, 255, 255, 0.25),
    2px 2px 6px 0px rgba(66, 83, 107, 0.22) inset;
}
.sap-red-btn button,
.sap-red-btn a {
  background: linear-gradient(0deg, #ee3124 0%, #ee3124 100%);
  /*box-shadow: 6px 6px 36px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 13px 0px rgba(255, 255, 255, 0.6),
    4px 4px 7px 0px rgba(0, 0, 0, 0.07);*/
}
.sap-red-btn button:hover,
.sap-red-btn a:hover {
  color: #fff;
}

.sap-black-btn button,
.sap-black-btn a {
  background: #2d2926;
  /*box-shadow: 6px 6px 36px 0px rgba(0, 0, 0, 0.06), -4px -4px 13px 0px #fff,
    4px 4px 7px 0px rgba(0, 0, 0, 0.07);*/
}

.red-txt {
  color: #ee3124;
  text-shadow: -4px -4px 13px rgba(255, 255, 255, 0.5);
}
.red-text {
  color: #ee3124;
}
/**************/
.home-page {
  overflow: hidden;
}
.top-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.top-header-inner {
  display: flex;
  justify-content: space-between;
  padding: 21px 0px 21px 0px !important;
}
.header-lf,
.header-rt {
  color: #2d2926;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header-lf ul li,
.header-rt ul li {
  display: inline;
  border-right: 1px solid;
  padding: 0px 22px 0px 9px;
  color: #2d2926;
}
.header-lf ul li:last-child,
.header-rt ul li:last-child {
  border: none;
}

.nav-top {
  padding: 0 !important;
}

/**************/

.set-logo {
  width: 95px;
  height: 90px;
  padding: 0;
}
.set-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* .nav-top .nav-item.dropdown:hover .dropdown-menu {
  display: block;
} */
.nav-top .dropdown-item.active,
.nav-top .dropdown-item:active {
  background: #ee3124;
}
.nav-top .dropdown-item:hover {
}
.nav-top .navbar-nav .nav-link {
  color: #2d2926;
  padding: 0px 16px !important;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 76px;
}

.nav-item.setalign {
  display: flex;
  align-items: center;
}

/**************/

.hero-ban-sec {
  background-image: url(../../assets/images/bannerbg.png);
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  min-height: 600px;
  position: relative;
  padding: 0px;
}

.hero-ban-content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.hero-ban-content-inner {
  font-size: 68px;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 80px;
  color: #fff;
  text-align: center;
  text-shadow: -4px -4px 13px rgba(0, 0, 0, 0.5);
}

.header-combo-btn {
  display: flex;
  justify-content: center;
  padding: 30px 0px;
}
.set-hd-btn-red {
  padding: 0px 21px 0px 0px;
}
.set-hd-btn-red a {
  min-width: 180px;
  height: 45px;
}
.set-hd-btn-light a {
  min-width: 125px;
  height: 45px;
}
.checkout-hd a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.checkout-hd svg {
  margin-right: 5px;
}

/*******banner-bottom*******/
.banner-bottom-sec {
  padding: 0px 0px 60px 0px;
  margin-top: -60px;
}

.banner-bot-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 28px;
  width: 100%;
}
.top-title {
  color: #000;
  text-align: center;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  padding: 0px 0px 9px 0px;
}

.bot-para {
  color: #2d2926;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 200% */
  letter-spacing: -0.14px;
}

.banner-bot-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  min-height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 27px 36px 27px;
}
.banner-section img {
  width: 45%;
  height: 500px;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
}
.banner-section {
  position: relative;
  height: 500px;
  display: flex;
  align-items: center;
}
.banner-section:before {
  content: '';
  position: absolute;
  width: 55%;
  height: 500px;
  background: #B71616;
  z-index: -1;
}
.banner-section .cs-content {
  max-width: 45%;
  padding: 0px 0 0 100px;
}
.cs-content h2 {
  font-size: 52px;
  color: #fff;
  margin: 0 0 20px;
  font-weight: bold;
}

.cs-content a {
  color: #fff;
  text-decoration: none;
}

.action-sec a {
  border-radius: 58px;
  background: linear-gradient(0deg, #000 0%, #000 100%), lightgray 50% / cover no-repeat;
  padding: 12px 35px;
  display: inline-block;
  margin: 20px 10px 0 0px;
  font-weight: 600;
}

.action-sec a:last-child {
  border-radius: 50px;
  background: #FFF;
   color: #000;
}
.side-icon {
  position: absolute;
  bottom: -76px;
  z-index: 1;
}

/*******free-assessment-sec*******/

.free-assessment-sec {
  background: url(../../assets/images/assess-banner.png);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 53px 0px;
}
.free-assessment-sec:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(0 0 0 / 75%);
  height: 100%;
}
.free-assess-inner {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
}
.free-assess-lf .lg-heading {
  color: #fff;
  padding: 0px 0px 5px;
}

.free-assess-rt {
  display: flex;
  align-items: center;
  justify-content: end;
}
.assess-para {
  color: #fff;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.18px;
}

.free-assess-inner .sap-red-btn {
  min-width: 232px;
  height: 55px;
  position: relative;
}
.free-assess-inner .sap-red-btn:before {
  position: absolute;
  content: url(../../assets/images/icon-arrow.png);
  right: 260px;
  bottom: -33px;
}

/*******learning section*******/

.learning-sec {
  padding: 89px 0px 0px;
}
.learning-sec:after {
  position: absolute;
  content: url(../../assets/images/rockett.png);
  right: 13%;
  bottom: -7%;
  animation: bounce2 2s ease infinite;
}
.galaxy-icon {
  position: absolute;
  right: 9%;
  bottom: -24%;
}
@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.learning-sec:before {
  position: absolute;
  content: url(../../assets/images/star-icon.png);
  top: 33px;
  left: 33px;
  animation-name: twinkle;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes twinkle {
  50% {
    transform: scale(0.7);
    opacity: 0.7;
  }
}

.learning-con .lg-heading {
  text-align: center;
  padding: 0 0 43px 0;
}
.learning-image img {
  width: 100%;
  height: 100%;
}

.step-guide {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 34px;
}

.learning-box {
  position: relative;
  max-width: 262px;
}
/* .steps-inner {
    position: absolute;
    top: 23px;
    left: 24px;
} */
.step-icon {
  top: 30px;
  position: absolute;
  left: 86px;
}

.step-txt {
  top: 190px;
  position: absolute;
  left: 86px;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 800;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  padding: 21px 0px 10px 0px;
  color: #2d2926;
}
.step-count {
  top: 108px;
  position: absolute;
  left: 86px;
}
.step-hd {
  color: #fff;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 2px 0px;
}
.step-no {
  color: #fff;
  font-family: Urbanist;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/*******checkout video*******/

.checkout-vd-sec {
  min-height: 280px;
  overflow: hidden;
}

.checkout-vd-sec:before {
  content: url(../../assets/images/vd-bg.png);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(0 0 0 / 80%);
  height: 100%;
}
.vd-title {
  color: #fff;
  text-align: center;
  font-family: Urbanist;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
.checkout-vd-con {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 77%;
  left: 50%;
}
.set-video {
  z-index: 1;
  position: relative;
  bottom: -110px;
}
.set-video:before {
  position: absolute;
  content: url(../../assets/images/ray-icon.png);
  top: -42px;
  left: -38px;
  animation-name: twinkle;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.set-video iframe {
  border-radius: 10px;
  border: 5px solid #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
}

/*******course categories*******/

.course-cg-sec {
  padding: 90px 0px 115px;
}
.course-cg-sec {
  padding: 90px 0px 115px;
}
.course-cg-sec:after {
  position: absolute;
  content: url(../../assets/images/star-icon.png);
  top: 33px;
  right: 33px;
  animation-name: twinkle;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes twinkle {
  50% {
    transform: scale(0.7);
    opacity: 0.7;
  }
}

.course-cg-sec:before {
  position: absolute;
  content: url(../../assets/images/book-icon.png);
  bottom: 59px;
  left: 24px;
  animation: moveInRight 4s ease-out infinite;
}

@keyframes moveInRight {
  0% {
    opacity: 0.5;
    transform: translateX(100px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.cag-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
}
.cag-bot {
  padding: 0px 20px 0px 20px;
}
.course-cg-con .xs-heading {
  text-align: center;
  padding: 0 0 4px 0;
}

.course-cg-con .lg-heading {
  text-align: center;
  padding: 0 0 50px 0;
}
.cag-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 29px;
  padding: 0px 0px 50px 0px;
}
.cag-img img {
  width: 100%;
}
.cag-title {
  color: #000;
  font-family: Urbanist;
  font-size: 21px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  padding: 14px 0px 10px;
}
.cag-rt-btn a {
  color: #ee3124;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}
.cag-combo-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 20px 0px;
}
.cag-combo-btn .sap-black-btn a {
  min-width: 140px;
}
.cag-foot {
  display: flex;
  justify-content: center;
}

/*******partner carausel*******/

.partner-carausel-sec {
  background: url(../../assets/images/owl-banner.png);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 77px 0px;
  overflow: hidden;
}
.partner-carausel-sec:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(0 0 0 / 80%);
  height: 100%;
}

.partner-carausel-sec .owl-carousel .owl-stage-outer {
  width: 100% !important;
}
.partner-carausel-con {
  text-align: center;
}
.partner-carausel-con .xs-heading {
  padding: 0 0 4px 0;
}

.partner-carausel-con .lg-heading {
  color: #fff;
  padding: 0 0 47px 0;
}
.partner-con-inner {
  max-width: 139px;
  height: 25px;
}
.partner-con-inner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cs-slide {
  width: 300px;
  height: 80px;
  background: #fff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner-carausel-con .owl-nav {
  display: none;
}

/*******get in touch form*******/

.get-in-touch-sec {
  padding: 90px 0px;
  /* background:url(../../assets/images/touchbg.png);width:100%;height:100%;background-size:cover; */
  background: #fef6f1;
  position: relative;
  margin-bottom: 0px;
}
.get-in-touch-sec:before {
  position: absolute;
  content: url(../../assets/images/frame.png);
  top: 6px;
  left: 40px;
  animation: Shake 2s linear infinite;
}

@keyframes Shake {
  0% {
    transform: rotate(5deg);
  }

  25% {
    transform: rotate(-6deg);
  }

  50% {
    transform: rotate(5deg);
  }

  75% {
    transform: rotate(-6deg);
  }

  100% {
    transform: rotate(5deg);
  }
}
.get-in-touch-sec:after {
  position: absolute;
  content: url(../../assets/images/touchrt-icon.png);
  top: 20px;
  right: 0;
}
.get-in-toch-con {
  text-align: center;
}
.get-in-toch-con .xs-heading {
  padding: 0 0 4px 0;
}
.get-in-toch-con .lg-heading {
  padding: 0 0 40px 0;
}
.form-inner {
  position: relative;
  z-index: 1;
}
.form-inner form {
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 39px;
  border-radius: 10px;
  background: #fff;
}
.form-inner .form-control,
.form-inner select.form-select.set-select {
  border-radius: 5px;
  height: 50px;
  margin: 0px 0px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(45, 41, 38, 0.6) !important;
  font-family: Raleway;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.14px;
}
.form-inner .sap-black-btn {
  display: flex;
  justify-content: center;
}

/*******************footer****************/

.footer-icons {
  display: flex;
  justify-content: space-between;
  align-items: end;
  /* background: #fef6f1; */
  /* background:url(../../assets/images/touch.png);width:100%;height:100%;background-size:cover;background-position: center; */
  margin-bottom: -19px;
}
.footer-top-icon {
  width: 78px;
  height: 105px;
  position: relative;
  left: 15px;
  top: 12px;
}
.footer-top-icon-rt {
  width: 86px;
  height: 106px;
  position: relative;
  top: 12px;
  right: 15px;
}
.footer-top-icon img,
.footer-top-icon-rt img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  animation: Shake 2s linear infinite;
}

@keyframes Shake {
  0% {
    transform: rotate(5deg);
  }

  25% {
    transform: rotate(-6deg);
  }

  50% {
    transform: rotate(5deg);
  }

  75% {
    transform: rotate(-6deg);
  }

  100% {
    transform: rotate(5deg);
  }
}
.footer-border img {
  width: 100%;
}
.footer-border {
  position: relative;
  top: 5px;
}
.footer-sec {
  background: #2d2926;
  position: relative;
}
/*.footer-sec:before {
    position: absolute;
    content: url(../../assets/images/footer-border.png);
}*/
.footer-inner {
  padding: 63px 0px 60px 0px;
}
.footer-col-title {
  color: #fff;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  padding: 25px 0px 20px 0px;
}
.footer-para {
  color: #fff;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.14px;
  padding: 0px 0px 23px 0px;
}
.footer-list ul li a {
  color: #fff;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.14px;
  transition: 0.3s all ease-in-out;
  text-decoration: none;
}
.footer-list ul li a:hover {
  color: #ed1c24;
}
.footer-list ul li a:before {
  content: url(../../assets/images/li-arrow.png);
  padding: 0px 10px 0px 0px;
}

.footer-grid-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
/* .footer-col {
  width: 24%;
  padding: 0px 10px 0px 0px;
} */
.footer-col:nth-child(2) {
  padding-left: 45px;
}

.footer-logo {
  margin: -14px 0px 15px 0px;
  max-width: 100px;
  height: 60px;
}
.footer-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.social-icons {
  display: flex;
}
.social-icons a {
  border: 2px solid #fff;
  padding: 5px;
  border-radius: 100px;
  width: 41px;
  height: 41px;
  display: flex;
  margin: 0px 4px;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
}
.social-icons a:hover {
  background: #ed1c24;
}
.social-icons i {
  color: #fff;
}
.social-icons img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cpyrt-sec {
  background: #2d2926;
  border-top: 1px solid rgb(255 255 255 / 20%);
}

.cpyrt-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cpyrt-txt {
  padding: 25px 0px 25px;
  color: #fff;
  font-family: Urbanist;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
}

/* new css start here */

.sch_event {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  padding: 17px;
  height: 231px;
  overflow: auto;
  margin-top: 15px;
}
/* .july_num {
  padding: 12px 19px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 10px;
} */
.sch_event_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #7575757d;
}
.sch_event_inner {
  display: flex;
  align-items: center;
}
.july_num {
  padding: 12px 19px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  border-radius: 10px;
}
.july_num h2 {
  color: #EE3124;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  font-family: 'Urbanist', sans-serif;
  margin: 0;
}
.july_num h4 {
  color: #000;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  font-family: 'Urbanist', sans-serif;
  margin: 0;
}
.evnt_head h2 {
  margin: 0 0 7px 25px;
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
  color: #000;
  font-family: 'Urbanist', sans-serif;
}
.evnt_head h4 {
  margin: 0 0 0 25px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #2d2926;
  font-family: 'Raleway', sans-serif;
}
.sch_view button {
  padding: 11px 34px;
  background-color: #fff;
  border-radius: 58px;
  border: 1px solid #EE3124;
  color: #EE3124;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  font-family: 'Urbanist', sans-serif;
}
hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}
.event h2 {
  color: #ff0033;
  font-weight: bold;
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
}





/********************************************************************************/

@media (max-width: 1199px) {
}

/***************/

@media (max-width: 991px) {
  .free-assess-inner .sap-red-btn:before {
    display: none;
  }
}

/***************/

@media (max-width: 767px) {
  .lg-heading {
    font-size: 38px;
    line-height: 44px;
  }
  .hero-ban-content-inner {
    font-size: 35px;
    line-height: 35px;
  }
  .vd-title {
    font-size: 31px;
    line-height: 38px;
  }
  .top-header {
    display: none;
  }
  .nav-top .navbar-nav .nav-link {
    padding: 16px 10px 16px 10px !important;
    text-align: left;
    justify-content: start;
    min-height: auto;
  }
  .nav-item.setalign {
    padding: 10px 0 20px;
  }

  .banner-bot-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .free-assess-inner {
    grid-template-columns: auto;
  }
  .social-icons {
    padding: 0 0 40px;
  }
  .free-assess-lf {
    padding: 0 0 50px 0;
  }
  .free-assess-inner .sap-red-btn:before {
    display: none;
  }
  .free-assess-rt {
    justify-content: center;
  }

  .step-guide {
    grid-template-columns: repeat(1, 1fr);
  }

  .set-video iframe {
    height: 429px;
  }
  .cag-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .cs-slide {
    width: 100%;
  }
  .footer-col {
    width: 100%;
  }
  .footer-inner {
    padding: 63px 0px 25px 0px;
  }
  .cpyrt-txt {
    padding: 25px 0px 6px;
  }
  .cpyrt-sec {
    padding: 0px 0px 30px;
  }
  .footer-grid-box {
    padding: 20px;
  }
  .footer-col:nth-child(2) {
    padding-left: 0;
  }
  .footer-border {
    top: 8px;
  }
}

/***************/

@media (max-width: 499px) {
  .side-icon {
    bottom: -50px;
  }
  .learning-sec:after {
    right: 9%;
    bottom: -5%;
  }
  .galaxy-icon {
    right: -4%;
    bottom: -6%;
  }
  .set-hd-btn-red {
    padding: 0px 6px 0px 0px;
  }
  .set-video {
    bottom: -98px;
    display: flex;
    justify-content: center;
  }
  .checkout-vd-sec {
    min-height: 195px;
  }
  .set-video iframe {
    height: 209px;
    width: 90%;
  }
  .vd-title {
    font-size: 24px;
    line-height: 35px;
  }
  .set-video:before {
    left: 0;
  }
  .get-in-touch-sec:after {
    top: 256px;
    right: 0;
  }
  .form-inner {
    padding: 39px 8px;
  }
}

/***************/
@media (min-width: 425px) and (max-width: 499px) {
  .hero-ban-content {
    width: 375px;
  }
}

/***************/

@media (min-width: 500px) and (max-width: 767px) {
  .hero-ban-content {
    width: 500px;
  }
  .hero-ban-content-inner {
    font-size: 36px;
    line-height: 49px;
  }
}

/***************/

@media (min-width: 768px) and (max-width: 1199px) {
  .hero-ban-content {
    width: 1020px;
  }
}
/***************/

@media (min-width: 768px) and (max-width: 991px) {
  .hero-ban-content-inner {
    font-size: 64px;
  }
  .lg-heading {
    font-size: 40px;
  }
  .cag-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .header-lf ul li,
  .header-rt ul li {
    padding: 0px 5px 0px 9px;
  }
  .header-rt ul li a{
    text-decoration: none !important;
  }
  .nav-top .navbar-nav .nav-link {
    padding: 30px 13px 30px 0px;
  }
  .step-guide {
    grid-template-columns: repeat(2, 1fr);
  }
  .learning-sec:after {
    right: 8%;
    bottom: -7%;
  }
  .galaxy-icon {
    right: -2%;
    bottom: -15%;
  }
  .social-icons a {
    width: 40px;
    height: 32px;
    font-size: 14px;
  }
}
/***************/

@media (min-width: 992px) and (max-width: 1199px) {
  .step-guide {
    grid-template-columns: repeat(3, 1fr);
  }

  .learning-sec:after {
    right: 4%;
    bottom: -5%;
  }
  .galaxy-icon {
    right: -4%;
    bottom: -17%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .cs-slide {
    width: 255px;
  }
  .hero-ban-content {
    width: 1020px;
  }

  .learning-sec:after {
    right: 10%;
    bottom: -18%;
  }
  .galaxy-icon {
    right: 2%;
    bottom: -33%;
  }
}

/***************/

@media screen and (min-width: 1220px) {
  .hero-ban-content-inner {
    font-size: 60px;
  }
}



 .colsubmenu {
  padding: 10px;
  min-height: 280px;
}


.dropdown-item {
  text-transform: capitalize;
}

.colsubmenu:hover {
  padding: 10px;
}

.dropdown-item:hover {
  background: #b71616 !important;
  color: #fff !important;
  border-radius: 5px;
}

.dropdown-item {
  color: #2d2926 !important;
  font-size: 14px !important;
}

/* .nav-item.dropdown:hover {
  background: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
} */

.dropdown-menu.megamenu.show {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  background: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 500px;
  border: none;
}

.dropdown-menu.megamenu {
  border: none;
  background: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 500px;
  border: none;
  border-radius: 5px;
}

.nav-link.dropdown-toggle.show,
.nav-link.dropdown-toggle.show:hover:visited {
  background: #f9f9f9;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
}

.nav-top .nav-item.dropdown:hover .dropdown-menu {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  left:0;
}
.nav-top .nav-item.dropdown:hover.dropdown-menu-block {
  display: block;
}

.nav-top .dropdown-item.active,
.nav-top .dropdown-item:active {
  background: #ee3124;
}

.nav-top .navbar-nav .nav-link {
  color: #2d2926;
  padding: 0px 16px !important;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 76px;
}

.nav-item.setalign {
  display: flex;
  align-items: center;
}

.colsubmenu li:nth-child(1) a {
  font-size: 14px !important;
  font-weight: 500;
  text-decoration: none;
  text-transform: capitalize;
}


.list-group-item-action:hover {
  background: #b71616;
  color: #fff;
  font-weight: 600;
  border-radius: 18px;
  border: none;
}

.list-group-item.list-group-item-action{

  border:none !important;
}


a.list-group-item.list-group-item-action.heading {
    border-bottom: 2px solid #b71616 !important;
    color: #ee3124;
    margin-bottom: 10px;
    max-width: 115px;
    text-align: center;
  
}

a.list-group-item.list-group-item-action:hover {
  background: #ee3124;
  color: white;
}

.action-sec2 a
{   border-radius: 58px;
    background: linear-gradient(0deg, #ee3124 0%, #ee3124 100%), lightgray 50% / cover no-repeat;
    padding: 9px 33px;
    display: inline-block;
    margin: 15px 10px 0 0px;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
}
.cs-header-menu.open {
  display: flex;
}
span.close-header-menu {
  position: absolute;
  right: 30px;
  top: 50px;
}
span.cpyrt-txt a {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  margin: 25px 8px; 
}

/* .menu-heading {
  border-bottom: 2px solid #b71616!important;
  color: #ee3124;
  margin-bottom: 10px;
  max-width: 115px;
  text-align: center;
} */







/* new banner css */
/* 10-02-2025 */

a {
  text-decoration: none;
}
.hero-section {
  background: #c62828;
  position: relative;
}
.hero-section .hero-section-main .hero_image img {
  width: 100%;
}

.hero-section-main {
  width: 90%;
  margin: 0 auto;
  padding: 80px 0;
}
.hero-content_text h2 {
  color: #fff;
  font-weight: 400;
  font-size: 54px;
  line-height: 72px;
}
.hero-content_text h2 span {
  font-weight: 700;
}
.hero-content_text h3 {
  font-size: 44px;
  font-weight: 400;
  color: rgb(193, 193, 18, 1);
}
.hero-content img {
  width: 100px;
  height: auto;
}

.btn-assessment {
  background: #000;
  color: white;
  padding: 12px 40px;
  border-radius: 60px;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
}
.enroll_btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 50px 0 0 0;
}
.btn-enroll {
  background: white;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 40px;
  border-radius: 60px;
}
.prepration_sec {
  position: relative;
  top: -50px;
}
.prepration_main {
  width: 80%;
  margin: 0 auto;
}
.prepration_main .pre_card h2 {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}
.prepration_main .pre_card p {
  font-size: 18px;
  font-weight: 500;
  color: #636868;
  letter-spacing: 0.7px;
}
.prepration_main .pre_card {
  padding: 30px;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  background: #ffff;
}
.bannerimage::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(../../assets/images/main\ image.png);
  height: 100%;
  width: auto;
  background-repeat: no-repeat;
  width: 50%;
  background-position: center right;
}

@media (max-width: 1024px) {
  .prepration_main .pre_card h2 {
    font-size: 28px;
  }
  .prepration_main .pre_card {
    padding: 20px;
  }
  .hero-content_text h3 {
    font-size: 36px;
  }
}
@media (max-width: 768px) {
  .prepration_main .pre_card h2 {
    font-size: 22px;
  }
  .hero-content_text h2 {
    color: #fff;
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
  }
  .hero-content_text h3 {
    font-size: 28px;
  }
  .prepration_main .pre_card {
    margin-bottom: 20px;
}
}
@media (max-width: 425px) {
  .bannerimage::after {
    display: none;
  }
  .prepration_main .pre_card{margin-bottom: 20px;}
}
