/****************history-page css****************/
.courses-page {
  overflow: hidden;
}
.courses-ban-sec:before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 40%);
}
.courses-ban-img img {
  width: 100%;
  height: 100%;
}
.courses-ban-content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.courses-ban-content .lg-heading {
  color: #fff;
  padding: 0 0 31px 0;
}
.breadcrumb-hd {
  text-align: center;
  color: #fff;
}
.breadcrumb-hd a {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px 5px 0 5px;
}

.courses-page .free-assessment-sec {
  background: url(../../assets/images/assess-banner.png);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 53px 0px;
}
.courses-page .course-cg-sec:before {
  position: absolute;
  content: url(../../assets/images/book-icon.png);
  left: 0;
  top: 50%;
  animation: moveInRight 4s ease-out infinite;
}

.courses-page .free-assess-inner .sap-red-btn a {
  border: 2px solid #fff;
}

/**********************************************/

@media (max-width: 499px) {
  .courses-ban-img img {
    width: 100%;
    height: 100%;
    min-height: 115px;
  }

  .courses-ban-content .lg-heading {
    font-size: 26px;
    padding: 0;
  }
}
