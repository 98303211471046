/****************blog-details css****************/

.blog-detail-page {
  overflow: hidden;
}

.blog-detail-ban-img img {
  width: 100%;
  height: 100%;
}
.blog-detail-ban-content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.blog-detail-ban-content .lg-heading {
  color: #fff;
  padding: 0 0 31px 0;
  text-align: center;
  line-height: 56px;
}
.blog-detail-lf-sec .sub-hd {
  color: #2d2926;
  font-family: Urbanist;
  font-size: 15px;
  font-style: normal;
  font-weight: 800 !important;
  line-height: 25px !important;
}
.blog-detail-lf-img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 25px 0;
  max-width: 768px;
}
.blog-detail-lf-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.blog-detail-lf-sec {
  padding: 65px 0px 40px;
}
.blog-detail-lf-inner .text-para p {
  font-weight: 500;
  line-height: 28px;
}
.blog-detail-lf-inner .text-para {
  padding: 0px 0px 22px 0px;
}
.combo-blogdetail-btn,
.combo-blogdetail-socialbtn {
  display: flex;
  align-items: center;
  padding: 11px 0px;
}

.combo-blogdetail-socialbtn svg,
.combo-blogdetail-btn svg {
  margin: 0 7px 0 0;
}
.combo-blogdetail-socialbtn .sap-light-btn a,
.combo-blogdetail-btn .sap-light-btn a {
  min-width: 148px;
  box-shadow: none;
}
.combo-blogdetail-btn .sap-light-btn a {
  border: 1px solid #2d2926;
  margin: 0 5px;
}
.combo-blogdetail-socialbtn i {
  width: 18px;
  height: 18px;
}
.combo-blogdetail-socialbtn .sap-light-btn a {
  margin: 0 5px;
}

.sap-light-btn.fb a {
  border: 1px solid #3b5998;
  background: #3b5998;
  color: #fff;
}
.sap-light-btn.watsap a {
  border: 1px solid #25d366;
  background: #25d366;
  color: #fff;
}
.sap-light-btn.tw a {
  border: 1px solid #1da1f2;
  background: #1da1f2;
  color: #fff;
}
.sap-light-btn.lkdn a {
  border: 1px solid #0077b5;
  background: #0077b5;
  color: #fff;
}

.combo-next-prevbtn .sap-black-btn a {
  box-shadow: none;
  min-width: 140px;
}
.combo-next-prevbtn {
  display: flex;
  justify-content: space-between;
  padding: 56px 30px 63px 30px;
}
.combo-next-prevbtn i {
  padding: 0 8px 0 8px;
}

/*******************************************************/

@media (max-width: 767px) {
  .blog-detail-page .lg-heading {
    padding: 0 0 15px 0;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
  .center-content {
    flex-direction: column-reverse;
  }
  .middle-box {
    width: 100%;
  }
  .sidebar-courses-sec {
    margin: 65px auto;
    width: 100%;
  }

  .blog-detail-ban-img img {
    width: 100%;
    height: 100%;
    min-height: 220px;
  }
  .blog-detail-lf-sec {
    padding: 0;
  }
  .combo-blogdetail-socialbtn .sap-light-btn a,
  .combo-blogdetail-btn .sap-light-btn a {
    min-width: 125px;
  }
}
/***************/

@media (max-width: 499px) {
  .blog-detail-ban-content .lg-heading {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    width: 300px;
  }

  .text-para p {
    font-size: 14px;
  }
}
/***************/

@media (min-width: 768px) and (max-width: 991px) {
  .blog-detail-ban-content .lg-heading {
    color: #fff;
    padding: 0;
    font-size: 24px;
    line-height: 34px;
  }

  .center-content {
    flex-direction: column-reverse;
  }
  .middle-box {
    width: 100%;
  }

  .sidebar-courses-sec {
    margin: 65px auto;
    width: 100%;
  }
  .blog-detail-lf-sec {
    padding: 0;
  }
}

/***************/

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-detail-ban-content .lg-heading {
    font-size: 30px;
    line-height: 39px;
  }
  .stardetail-icon {
    top: -69px;
    right: -14%;
  }
  .blog-detail-lf-inner .text-para {
    padding: 0px 0px 112px 0px;
  }
}

/***************/

@media (min-width: 1200px) and (max-width: 1399px) {
  .blog-detail-ban-content .lg-heading {
    font-size: 34px;
  }
}

/***************/

@media screen and (min-width: 1400px) {
}
