.contactus-page {
    overflow: hidden;
}
.contactus-ban-sec:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 40%);
}
.contactus-ban-img img {
    width: 100%;
    height: 100%;
}
.contactus-ban-content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.contactus-ban-content .lg-heading {
    color: #fff;
    padding: 0 0 31px 0;
}
.contact-detail-sec {
    z-index: 1;
    margin-top: -35px;
}

.get-in-touch-sec.cloned-get-toch-contact {
    margin-bottom: 0;
}
.cloned-bbg-contactdt .icon-con {
    width: 43px;
    height: 43px;
}
.get-in-touch-sec.cloned-get-toch-contact {
    margin-bottom: 0;
    padding: 90px 0px 30px;
    background: #FFF;
}
.cloned-bbg-contactdt {
    grid-template-columns: repeat(4, 1fr);
}
.get-in-touch-sec.cloned-get-toch-contact:after {
    display: none;
}
.cloned-get-toch-contact .form-inner form{
 position: relative;
 z-index: 1;
}
.contact-dot-icon {
    position: absolute;
    right: -16px;
    top: -55px;
}
.cloned-bbg-contactdt .top-title {
    font-size: 18px;
    line-height: 29px;
}

.cloned-bbg-contactdt .bot-para {
    color: #434343;
    font-size: 15px;
    line-height: 24px; /* 160% */
    letter-spacing: -0.15px;
}
.cont-map-sec {
    margin-top: 80px;
    padding: 0px 0px 50px;
}
.cont-map-con {
    max-width: 1000px;
    margin: 0 auto;
}
.cont-map-con iframe {
    width: 100%;
    object-fit: contain;
}























/*******************************************************/

@media(max-width:767px) {

.contactus-page .lg-heading {
    padding: 0 0 15px 0;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
}
.contactus-ban-img img {
    width: 100%;
    height: 100%;
    min-height: 115px;
}
.cloned-bbg-contactdt {
    grid-template-columns: repeat(1, 1fr);
}
}
/***************/

 @media (max-width:499px) {

.contactus-ban-content .lg-heading {
    font-size: 21px;
    /* padding: 0; */
    line-height: 30px;
    text-align: center;
}

 }
/***************/

@media(min-width:768px) and (max-width:991px) {
.contactus-ban-content .lg-heading {
    color: #fff;
     padding: 0;
}
.contactus-ban-content .lg-heading {
    font-size: 31px;
}
.cloned-bbg-contactdt {
    grid-template-columns: repeat(2, 1fr);
}

}



/***************/

@media(min-width:992px) and (max-width:1199px) {
.contactus-ban-content .lg-heading {
    font-size: 31px;
}
.cloned-bbg-contactdt {
    grid-template-columns: repeat(2, 1fr);
}
}

/***************/

@media  screen and (min-width: 1400px) {

}
