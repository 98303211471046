/****************about-page css****************/
.about-page {
  overflow: hidden;
}
.about-ban-sec:before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 40%);
}
.about-ban-img img {
  width: 100%;
  height: 100%;
}
.about-ban-content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.about-ban-content .lg-heading {
  color: #fff;
  padding: 0 0 31px 0;
}
.breadcrumb-hd a {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px 5px 0 5px;
}
.breadcrumb-hd {
  text-align: center;
  color: #fff;
}

/*******about-us*******/

.aboutus-sec {
  padding: 90px 0px 25px;
}
.aboutus-lf .lg-heading {
  padding: 0 0 15px 0;
}
.aboutus-lf .text-para p {
  padding: 0 0 11px 0;
  margin: 0;
}
.aboutus-inner {
  display: flex;
  flex-wrap: wrap;
}
.aboutus-lf {
  width: 100%;
  flex-basis: 100%;
  max-width: 60%;
  padding: 0px 30px 0px 0px;
}
.aboutus-rt {
  width: 100%;
  flex-basis: 100%;
  max-width: 40%;
}
.aboutrt-img {
  max-width: 570px;
  width: 100%;
  height: 442px;
  padding: 0px 10px 0px 10px;
}
.aboutrt-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.book-icon {
  position: relative;
  left: -13%;
  bottom: 78px;
  animation: moveInRight 4s ease-out infinite;
}

@keyframes moveInRight {
  0% {
    opacity: 0.5;
    transform: translateX(100px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/*******counter-sec*******/
.counter-sec {
  background: #fffefd;
  z-index: 1;
}
.prg-counter:after {
  position: absolute;
  content: url(../../assets/images/ray-iconabt.png);
  right: 15px;
  animation-name: twinkle;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  z-index: 1;
  bottom: -55px;
}

.counter-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 48px 20px 24px 20px;
  text-align: center;
}
.counter-box .prg-count-title {
  height: 50px;
  border-radius: 10px;
  background: #2d2926;
  color: #fff;
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.prg-count:after {
  content: "K";
}
.prg-count.symb-plus:after {
  content: "+";
}
.prg-count.symb-percent:after {
  content: "%";
}
/******teaching-learning-sec*****/

.teaching-learn-sec {
  background: #fffefd;
  padding: 124px 0px 50px;
}
.tl-con-rt .xs-heading {
  padding: 0 0 13px 0;
}
.tl-con-rt .lg-heading {
  padding: 0 0 34px 0;
}
.tl-con-rt {
  padding: 0 0 0 30px;
}
.tl-inner {
  display: flex;
  flex-wrap: wrap;
}

.tl-con-lf {
  width: 100%;
  flex-basis: 100%;
  max-width: 40%;
  position: relative;
}
.tl-con-rt {
  width: 100%;
  flex-basis: 100%;
  max-width: 60%;
}
.tl-con-lf-img {
  max-width: 440px;
  width: 100%;
  height: 440px;
  border-radius: 50px 500px 500px 500px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  /* position: relative; */
}

.tl-con-lf-img img {
  width: 100%;
  height: 100%;
  object-fit: none;
  border-radius: 50px 500px 500px 500px;
  position: relative;
}
.tl-con-lf-img:before {
  content: url(../../assets/images/dots.png);
  top: -17px;
  left: 64%;
  position: absolute;
}

.idea-icon {
  position: absolute;
  left: -86px;
  top: -67px;
  z-index: 1;
}
.percent-icon {
  position: absolute;
  bottom: -100px;
  right: -42px;
}

.strategy-list ul li:before {
  font-family: "FontAwesome";
  content: "\f061";
  border: 2px solid #000;
  border-radius: 50%;
  font-size: 13px;
  padding: 3px;
  margin: 0px 5px 0px 0px;
}
.tl-con-rt .strategy-list ul li {
  color: #2d2926;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 27px;
}
.strategy-list ul li {
  color: #2d2926;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}
.strategy-list p {
  color: #434343;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  padding: 0px 0px 15px 30px;
}

/*******we-offer-sec*******/
.we-offer-sec {
  padding: 110px 0px 0px;
}
.offer-con-lf .xs-heading {
  padding: 0 0 13px 0;
}
.offer-con-lf .lg-heading {
  padding: 0 0 13px 0;
}

.offer-inner {
  display: flex;
  flex-wrap: wrap;
}
.offer-con-lf {
  width: 100%;
  flex-basis: 100%;
  max-width: 60%;
  padding: 0px 40px 0px 0px;
}
.offer-con-rt {
  width: 100%;
  flex-basis: 100%;
  max-width: 40%;
  padding: 84px 0px 30px;
}

.offer-con-rt-img {
  max-width: 440px;
  width: 100%;
  height: 440px;
  border-radius: 500px 50px 500px 500px;
  position: relative;
}
.offer-con-rt-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 500px 50px 500px 500px;
  position: relative;
}
.offer-con-rt-img:before {
  position: absolute;
  content: url(../../assets/images/dots.png);
  top: 65%;
  left: 68%;
}

.offer-con-lf .strategy-list ul li {
  padding: 0px 0px 12px 0px;
  color: #2d2926;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 27px;
}
.rocket-iconabt {
  position: absolute;
  top: 88px;
  left: 0;
  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
/*******vision-sec*******/
.vision-sec {
  padding: 110px 0px 0px;
}
.vision-con-rt .xs-heading {
  padding: 0 0 13px 0;
}
.vision-con-rt .lg-heading {
  padding: 0 0 24px 0;
}
.galaxy-iconabt {
  position: absolute;
  top: 0;
  right: 0;
  animation: moveInRight 4s ease-out infinite;
}
.vision-con-rt {
  padding: 0 0 0px 40px;
}

.vision-inner {
  display: flex;
  flex-wrap: wrap;
}
.vision-con-rt {
  width: 100%;
  flex-basis: 100%;
  max-width: 60%;
}
.vision-con-lf {
  width: 100%;
  flex-basis: 100%;
  max-width: 40%;
  padding: 84px 0px;
}

.vision-con-lf-img {
  max-width: 440px;
  width: 100%;
  height: 440px;
  border-radius: 50px 500px 500px 500px;
  position: relative;
}
.vision-con-lf-img:before {
  position: absolute;
  content: url(../../assets/images/dots.png);
  top: 0%;
  left: 70%;
}
.vision-con-lf img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px 500px 500px 500px;
  position: relative;
}

/*******************************************************/

@media (max-width: 767px) {
  .about-page .lg-heading {
    padding: 0 0 15px 0;
    font-size: 30px;
    line-height: 40px;
  }
  .aboutus-lf,
  .aboutus-rt,
  .tl-con-lf,
  .tl-con-rt,
  .offer-con-lf,
  .offer-con-rt,
  .vision-con-lf,
  .vision-con-rt {
    max-width: 100%;
    padding: 0px;
  }
  .aboutus-sec {
    padding: 55px 0px 25px;
  }
  .aboutrt-img {
    padding: 0;
    height: auto;
  }
  .book-icon {
    bottom: 7px;
  }
  .offer-con-lf .strategy-list {
    padding: 0px 0px 60px 0px;
  }
  .tl-con-rt {
    padding: 15px;
    margin-top: 23px;
  }

  .teaching-learn-sec {
    padding: 124px 0px 20px;
  }
  .tl-inner {
    padding: 0;
  }
  .tl-con-lf-img {
    box-shadow: none;
  }
  .tl-con-lf-img img,
  .offer-con-rt-img img {
    object-fit: cover;
  }
  .vision-sec {
    padding: 190px 0px 0px;
  }
}
/***************/

@media (max-width: 499px) {
  .about-ban-img img {
    width: 100%;
    height: 100%;
    min-height: 115px;
  }

  .about-ban-content .lg-heading {
    font-size: 26px;
    padding: 0;
  }
  .rocket-iconabt {
    top: 9px;
  }
  .tl-con-lf-img,
  .offer-con-rt-img,
  .vision-con-lf-img {
    height: auto;
  }
  .counter-box {
    margin-bottom: 20px;
  }
}

/***************/

@media (min-width: 768px) and (max-width: 991px) {
  .aboutus-lf,
  .aboutus-rt {
    max-width: 100%;
  }
  .book-icon {
    bottom: 9px;
  }
  .aboutrt-img {
    margin: 0 auto;
  }
  .tl-con-lf-img {
    box-shadow: none;
  }
  .tl-con-lf-img img,
  .offer-con-rt-img img,
  .vision-con-lf img {
    object-fit: contain;
  }
  .idea-icon {
    top: 19px;
  }
  .percent-icon {
    position: absolute;
    bottom: 29px;
    right: -42px;
  }
  .tl-con-lf-img:before {
    content: url(../../assets/images/dots.png);
    top: 94px;
    left: 60%;
    position: absolute;
  }
  .offer-con-rt-img:before {
    top: 52%;
    left: 57%;
  }
  .vision-con-lf-img:before {
    top: 14%;
    left: 57%;
  }
}

/***************/

@media (min-width: 992px) and (max-width: 1199px) {
  .offer-con-rt-img img,
  .vision-con-lf img,
  .tl-con-lf-img img {
    height: auto;
  }
  .tl-con-lf-img {
    box-shadow: none;
  }
  .offer-con-rt-img:before {
    top: 56%;
    left: 64%;
  }
  .percent-icon {
    bottom: -52px;
  }
  .book-icon {
    position: relative;
    left: -13%;
    bottom: 15px;
  }
}

/***************/

@media screen and (min-width: 1400px) {
  .percent-icon {
    right: 11px;
  }
}
