/****************blog-page css****************/
.blog-page {
  overflow: hidden;
}
.blog-cg-sec {
  padding: 110px 0px 0;
}
.blog-ban-sec:before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 40%);
}
.blog-ban-img img {
  width: 100%;
  height: 100%;
}
.blog-ban-content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.blog-ban-content .lg-heading {
  color: #fff;
  padding: 0 0 31px 0;
}
.breadcrumb-hd {
  text-align: center;
  color: #fff;
}
.breadcrumb-hd a {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px 5px 0 5px;
}

.cag-date {
  color: #434343;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blog-cg-sec:before {
  position: absolute;
  content: url(../../assets/images/frame.png);
  top: 6px;
  left: 40px;
  animation: Shake 2s linear infinite;
}

@keyframes Shake {
  0% {
    transform: rotate(5deg);
  }

  25% {
    transform: rotate(-6deg);
  }

  50% {
    transform: rotate(5deg);
  }

  75% {
    transform: rotate(-6deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

/**********************************************/

@media (max-width: 499px) {
  .blog-ban-img img {
    width: 100%;
    height: 100%;
    min-height: 115px;
  }

  .blog-ban-content .lg-heading {
    font-size: 26px;
    padding: 0;
  }
}
