body {
  font-family: Urbanist;
  color: #989898;
  font-size: 14px;
  line-height: 23px;
}

section {
  position: relative;
}

.xl-heading {
  font-size: 68px;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 80px;
}

.lg-heading {
  font-size: 47px;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 59px;
  color: #2d2926;
}

.xs-heading {
  font-size: 18px;
  font-family: Raleway;
  font-weight: 700;
  line-height: 21.13px;
  /*color: #EE3124ff;*/
}

.text-para p {
  font-size: 15px;
  font-family: Raleway;
  line-height: 27px;
  font-weight: 400;
  color: #434343;
}

ul {
  margin: 0;
  padding: 0px;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.sap-red-btn button,
.sap-red-btn a,
.sap-black-btn button,
.sap-black-btn a,
.sap-light-btn button,
.sap-light-btn a {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  min-height: 45px;
  border-radius: 58px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;
  font-family: Urbanist;
  max-width: 145px;
}

.sap-light-btn button,
.sap-light-btn a {
  background: #fff;
  color: #2d2926;
  box-shadow: 4px 4px 8px 0px rgba(66, 83, 107, 0.09),
    -4px -4px 16px 0px rgba(255, 255, 255, 0.25),
    2px 2px 6px 0px rgba(66, 83, 107, 0.22) inset;
}

.sap-red-btn button,
.sap-red-btn a {
  background: linear-gradient(0deg, #ee3124 0%, #ee3124 100%);
  box-shadow: 6px 6px 36px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 13px 0px rgba(255, 255, 255, 0.6),
    4px 4px 7px 0px rgba(0, 0, 0, 0.07);
}

.sap-black-btn button,
.sap-black-btn a {
  background: #2d2926;
  box-shadow: 6px 6px 36px 0px rgba(0, 0, 0, 0.06), -4px -4px 13px 0px #fff,
    4px 4px 7px 0px rgba(0, 0, 0, 0.07);
}

.red-txt {
  color: #ee3124;
  text-shadow: -4px -4px 13px rgba(255, 255, 255, 0.5);
}

.red-text {
  color: #ee3124;
}

.set-logo {
  width: 95px;
  height: 95px;
  padding: 0;
}

.set-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.font-30 {
  font-size: 30px;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 59px;
  color: #2d2926;
}

label sup {
  color: red;
}

.grey-back {
  background: #f2f2f2;
}

.set-btn {
  padding: 15px 0px 30px;
}

.form-group {
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
  font-family: "Raleway";
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
  border-color: #afa8a8 !important;
}

.student-type-page,
.current-student-page,
.new-student-page,
.course-selection-page,
.campus-classes-selection-page {
  overflow: hidden;
}

.form-inner {
  position: relative;
  z-index: 1;
}

.form-inner form {
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  padding: 39px;
  border-radius: 10px;
  background: #fff;
}

.form-inner .form-control,
.form-inner select.form-select.set-select {
  border-radius: 5px;
  height: 50px;
  margin: 0 0 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(45, 41, 38, 0.6) !important;
  font-family: Raleway;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.14px;
}

.form-inner .sap-black-btn {
  display: flex;
  justify-content: center;
}

/*****************student-type******************/

.student-type-page .form-inner {
  position: relative;
  z-index: 1;
  max-width: 700px;
  min-height: 400px;
  padding-top: 30px;
}

.student-type-page .form-inner form {
  box-shadow: 0 0 45px 2px rgba(0, 0, 0, 0.05);
  padding: 60px 50px 40px;
  border-radius: 10px;
  background: #fff;
}

.student-type-page .combo-btn {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 21px;
}

.student-type-page .combo-btn span {
  padding: 16px 15px 16px 15px;
}

.student-type-page .combo-btn .sap-light-btn a,
.combo-btn .sap-blue-btn a {
  min-width: 131px !important;
  height: 50px;
}

.student-type-page .sap-red-btn a {
  min-width: 200px;
  border-radius: 10px;
  height: 83px;
  font-size: 16px;
  padding: 10px;
}

/******************current-student(welcome back)*****************/

.current-student-page .form-inner {
  position: relative;
  z-index: 1;
  max-width: 700px;
  min-height: 400px;
  padding-top: 30px;
}

.current-student-page .form-inner .form-control {
  padding-left: 31px;
}

.current-student-page .form-inner form {
  box-shadow: 0 0 45px 2px rgba(0, 0, 0, 0.05);
  padding: 60px 50px 40px;
  border-radius: 10px;
  background: #fff;
}

.current-student-page .xs-heading {
  font-size: 18px;
  font-family: Raleway;
  font-weight: 700;
  line-height: 21.13px;
  color: #2d2926;
}

.current-student-page .form-inner label {
  font-size: 14px;
  color: #333;
  padding: 10px 0px;
}

.current-student-page .form-control::placeholder,
.current-student-page .form-select::placeholder {
  color: #a5a2a2;
}

.current-student-page .fa-icon i {
  position: absolute;
  left: 12px;
  top: 59px;
}

/******************new-student*****************/

.new-student-page .form-inner {
  position: relative;
  z-index: 1;
  max-width: 700px;
  min-height: 400px;
  padding-top: 30px;
}

.new-student-page .form-inner .form-control {
  margin: 0;
}

.new-student-page .form-inner form {
  box-shadow: 0 0 45px 2px rgba(0, 0, 0, 0.05);
  padding: 10px 50px 65px;
  border-radius: 10px;
  background: #fff;
}

.new-student-page .xs-heading {
  font-size: 18px;
  font-family: Raleway;
  font-weight: 700;
  line-height: 21.13px;
  color: #2d2926;
}

.new-student-page .form-inner label {
  font-size: 14px;
  color: #333;
  padding: 10px 0px;
}

.new-student-page .form-control::placeholder,
.new-student-page .form-select::placeholder {
  color: #a5a2a2;
}

.new-student-page .xs-heading {
  font-size: 18px;
  font-family: Raleway;
  font-weight: 700;
  line-height: 21.13px;
  color: #2d2926;
  padding: 60px 0px 15px;
  border-bottom: 1px solid rgba(117, 117, 117, 0.1);
  margin: 0px 0px 35px;
}

/****************course selection***************/

.course-selection-sec {
  padding-top: 60px;
}

.course-selection-inner {
  padding: 60px 50px 40px;
  position: relative;
  z-index: 1;
  max-width: 700px;
  min-height: 400px;
  box-shadow: 0 0 45px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: #fff;
}

.owl-nav {
  position: relative;
  right: 0;
  z-index: 99;
  font-size: 17px;
  bottom: 0;
  border-top-left-radius: 20px;
}

button.owl-next {
  position: absolute;
  width: 0px;
  right: 0;
}

button.owl-prev span {
  font-size: 0px;
}

button.owl-next span {
  font-size: 0px;
}

.owl-slide {
  padding: 20px 0px;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: block;
}

.course-selection-page .font-30 {
  font-size: 27px;
}

.course-selec-outer {
  padding: 40px 14px;
  color: #000;
  border: 1px solid #000;
  text-align: center;
  min-height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.course-selec-outer.active-cs {
  background: #ee3124;
  color: #fff;
  border: 1px solid #ee3124;
}

.course-selec-hd {
  font-size: 25px;
}

.course-selec-txt {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0px 10px;
}

button.owl-prev:before {
  position: absolute;
  content: "";
  background-image: url(../../assets/images/lft.png);
  width: 36px;
  height: 25px;
  left: -58px;
  bottom: 105px;
  background-repeat: no-repeat;
  background-position: right;
}

button.owl-next:after {
  position: absolute;
  content: "";
  background-image: url(../../assets/images/rt.png);
  background-repeat: no-repeat;
  background-position: right;
  width: 36px;
  height: 25px;
  right: -35px;
  bottom: 105px;
}

/****************campus courses-selection***************/


.campus-classes-sec {
  padding: 60px 0px;
}


.campus-classes-inner {
  padding: 60px 27px 40px;
  position: relative;
  z-index: 1;
  max-width: 700px !important;
  min-height: 400px;
  box-shadow: 0 0 45px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: #fff;
}

.campus-classes-selection-page .xs-heading {
  font-size: 18px;
  text-align: center;
  font-family: Raleway;
  font-weight: 700;
  line-height: 21.13px;
  color: #2d2926;
}

.campus-classes-inner .tab-course {
  padding: 30px 0px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border-bottom: none;
}

.campus-classes-inner .tablinks {
  padding: 20px 0px;
  min-width: 90px;
  color: #d31407 !important;
  border: 2px solid #d31407;
  background: #fff;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.campus-classes-inner .tablinks.react-tabs__tab--selected {
  padding: 20px 0px;
  min-width: 90px;
  color: #fff !important;
  border: 2px solid #d31407;
  background: #d31407;
}

.campus-classes-inner .title-hd {
  color: #000;
  font-weight: 800;
}

.campus-classes-inner .accordion-item {
  border: 2px solid #d31407 !important;
  margin-bottom: 20px;
}

.campus-classes-inner .title-hd {
  padding: 5px 0px 15px;
  font-size: 18px;
}

.campus-classes-inner .accordion-button:not(.collapsed)::after {
  content: "\f106";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  background: none;
  color: #fff;
}

.campus-classes-inner .accordion-button::after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  background: none;
  color: #d31407;
}

.campus-classes-inner .accordion-item h2 button {
  color: #a91207;
  font-weight: 600;
}

.campus-classes-inner button.accordion-button {
  background: #fff !important;
  padding: 16px;
}

.campus-classes-inner button.accordion-button:not(.collapsed) {
  background-color: #a91207 !important;
  color: #fff;
}

.campus-classes-inner button.accordion-button:focus {
  box-shadow: 0 0;
}

.campus-classes-inner .accordion-body {
  padding: 20px;
  color: #000;
}

.campus-classes-inner .acdn-time {
  color: #000;
  font-weight: 700;
}

.campus-classes-inner .bg-grey {
  background: #ddd8d8;
  display: inline-block;
  padding: 3px 6px;
}

.campus-classes-inner .acc-check-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.campus-classes-inner .form-check {
  flex-basis: 100%;
  width: 100%;
  max-width: 40%;
  padding: 15px 20px;
}

/****************enrollment summary***************/

.enrollment-sec {
  padding-top: 60px;
}

.enrollement-inner {
  padding: 20px 0px 40px;
  position: relative;
  z-index: 1;
  max-width: 700px;
  min-height: 400px;
  box-shadow: 0 0 45px 2px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.enrollement-inner .bg-grey {
  background: #ddd8d8;
  display: inline-block;
  padding: 3px 6px;
  color: #000;
}

.enrol-header,
.enrol-content {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  border-bottom: 1px solid rgb(0 0 0 / 8%);
  background: #f3f3f3;
}

.enrol-header .enrol-right {
  display: flex;
}

.enrol-content .enrol-right {
  display: flex;
  color: #000;
  font-weight: 700;
}

.enrol-campus,
.enrol-lesson,
.enrol-price {
  padding: 0px 5px 0px 5px;
  width: 85px;
  text-align: right;
}

.enrol-header {
  background: #d31407;
  color: #fff;
  font-weight: 700;
}

.term-course {
  padding: 0px 5px 0px 5px;
}

.enrol-header .enrol-right {
  color: #fff;
}

.enrol-course-hd {
  color: #000;
  font-weight: 700;
  padding-bottom: 5px;
}

.enrol-timings {
  color: #000;
  padding-bottom: 2px;
  font-weight: 500;
}

.enrol-course {
  padding-bottom: 5px;
  font-weight: 500;
}

.enrol-date {
  font-weight: 500;
}

.enrol-content:last-child {
  border-bottom: none;
}

.enrol-content {
  padding-bottom: 15px;
}

/****************Fee summary***************/

.fee-summary-sec {
  padding-top: 60px;
}

.fee-summary-inner {
  padding: 20px 27px 40px;
  position: relative;
  z-index: 1;
  max-width: 950px;
  min-height: 400px;
  box-shadow: 0 0 45px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: #fff;
}

.fee-sum-list li {
  list-style-type: auto;
}

.fee-sum-content {
  display: flex;
  padding: 20px 0px;
}

.fee-sum-lft {
  padding: 20px;
}

.fee-sum-rt {
  padding: 20px;
}

.fee-sum-lft .xs-heading,
.fee-sum-rt .xs-heading {
  color: #000;
  padding-bottom: 20px;
}

.fee-sum-rt .xs-heading {
  padding-left: 20px;
}

.fee-sum-lft button {
  background: #ee3124;
  border: 1px solid #ee3124;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-weight: 500;
}

.fee-sum-lft input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.fee-sum-list li {
  color: #6c6565;
  font-weight: 500;
}

.cart-total {
  padding: 20px;
}

.total-lf {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

.total-rt {
  color: #000;
}

.cart-total-btn {
  padding: 20px 0px;
}

.cart-total .sap-red-btn a {
  min-width: 225px;
}

.cart-total-box {
  border-bottom: 1px solid rgb(0 0 0 / 8%);
}

.cart-total-box-inner {
  display: flex;
  justify-content: space-between;
  max-width: 235px;
  padding: 14px 0px;
}

/****************payment confirmation***************/

.payment-confirmation-sec {
  padding-top: 60px;
}

.payment-confirmation-inner {
  padding: 20px 27px 40px;
  position: relative;
  z-index: 1;
  max-width: 950px;
  min-height: 400px;
  box-shadow: 0 0 45px 2px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 10px;
}

.payment-confirmation-inner .set-logo {
  margin: 0 auto;
}

.font-25 {
  font-size: 25px;
  font-family: Urbanist;
  line-height: 39px;
  color: #8d8585;
  padding: 20px 0px 15px;
}

.font-18 {
  font-size: 18px;
  font-family: Raleway;
  padding: 10px 0px 20px;
}

.font-20 {
  font-size: 20px;
  color: #890409;
  font-weight: 700;
  padding: 15px 0px 7px;
}

.font-18 a {
  color: #890409;
}

.pay-conf-chart table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

.pay-conf-chart tr {
  font-weight: 500;
}

/* .prev-next {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
} */
.prev-next {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  margin-top: -45px;
  z-index: 999;
  position: relative;
}
.prev-next button {
  background: linear-gradient(0deg, #EE3124 0%, #EE3124 100%);
  box-shadow: 6px 6px 36px 0px rgba(0, 0, 0, 0.06), -4px -4px 13px 0px rgba(255, 255, 255, 0.60), 4px 4px 7px 0px rgba(0, 0, 0, 0.07);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  min-height: 45px;
  border-radius: 58px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;
  font-family: Urbanist;
  max-width: 145px;
  border: 1px solid #ee3124;
}

/*********************media*********************/

@media (max-width: 767px) {
  .student-type-page .combo-btn {
    display: block;
  }

  .campus-classes-inner .form-check {
    max-width: 100%;
  }

  .font-30 {
    font-size: 23px;
    line-height: 33px;
  }

  .campus-classes-selection-page .xs-heading {
    padding: 15px;
  }

  .new-student-page .form-inner form {
    padding: 10px 25px 65px;
  }

  .current-student-page .form-inner form {
    padding: 60px 25px 40px;
  }

  .fee-sum-list ol {
    padding-left: 20px;
  }

  .fee-sum-content {
    display: block;
  }
}

/******************************************/

@media (max-width: 499px) {

  .enrol-campus,
  .enrol-lesson,
  .enrol-price {
    width: 60px;
  }
}