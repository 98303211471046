
/* event css */
body, p, h3, h5{
    margin: 0;
    padding: 0; 
    font-family: "Poppins", Sans-serif;   
    /* background-color: #fcf0d6; */
  }
  .seminar{
    padding: 30px;
  }
  .seminar_header h3{
    font-size: 25px;
    font-weight: 600;
    text-decoration: none;
    line-height: 1.1em;
  margin: 20px 0;
  color: #3d763e;
  }
  .semonar_topics {
    padding-top: 20px;
  }
  .semonar_topics li {
    padding-left: 46px;
    margin: 8px 0;
  }
  .venue_space {
    padding: 26px 0 4px 0;
  }
  .face_toseats{
    display: flex;
    gap: 70px;
  }
  .venue_sec {
    padding-bottom: 24px;
  }
  .venuebtn{
    margin-top: 24px;
  }
  .venuebtn button {
    background-color: #ed5565;
    color: #fff;
    font-weight: 500;
    padding: 11px 21px;
    border: 1px solid #ed5565;
    border-radius: 7px;
    font-size: 16px;
    cursor: pointer;
  }
  .face_toseats p span{
    color: #f14658;
  }
  .seminar_footer p{
    font-weight: 600;
  }
  .seminar_footer p span{
    color: #f14658;
  }
  
  /* 
  form pages css start here */
  
  .Selective h3{
    font-size: 25px;
    font-weight: 500;
  }
  .date_time p{
    color: #3d763e;
  }
  .date_time {
    display: flex;
    gap: 70px;
    margin: 24px 50px;
  }
  .available_seats p span{
    color: #da1327;
  }
  .available_seats {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 20px;
  }
  .booking_details h3 {
    text-align: center;
    padding: 20px 0;font-size: 20px;
  }
  .btn-seminar2{
    text-align: center;
  }
  .btn-seminar2 button{
    background-color: #5cb85c;
    color: #fff;
    font-weight: 500;
    padding: 8px 21px;
    border: 1px solid #5cb85c;
    border-radius: 7px;
    font-size: 16px;
    cursor: pointer;
  }
  .btn-seminar2 button:hover{
    background-color: #5cb85c;
    border: 1px solid #5cb85c;
  }
  
  
  @media only screen and (max-width: 575px) {
    .date_spacening{
        width: 35%;
    }
    .date_time {
        margin: 24px 0;
        gap: 0 ;
    }
  }