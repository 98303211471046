.branches-page {
    overflow: hidden;
}
.campus-detail-sec {
    padding: 90px 0px;
}
.branches-ban-sec:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 40%);
}
.branches-ban-img img {
    width: 100%;
    height: 100%;
}
.branches-ban-content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.branches-ban-content .lg-heading {
    color: #fff;
    padding: 0 0 31px 0;
    text-align: center;
}
.campus-box {
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 15px 14px 15px 14px;
    margin: 0px 0px 20px 0px;
}
.campus-middle-content .sap-black-btn a {
    color: #FFF;
    text-align: center;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.campus-middle-content .sap-black-btn i {
    padding: 0 7px 0 0;
}
.campus-map {
    max-width: 300px;
    height: 200px;
    width: 100%;
}
.campus-map img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.campus-hd {
    color: #2D2926;
    font-family: Urbanist;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px;
    padding: 0 0 21px;
}
.setcampus-subhd {
    padding: 51px 0px 23px;
}
.campusdt a {
    color: #434343;
    font-family: Raleway;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}
.campusdt i {
    padding: 0px 7px 0 0;
}
.campus-middle-content .campus-hd {
    padding: 15px 0 15px 0;
}

.campus-middle-content .sap-black-btn {
    padding: 13px 0px;
}
.cc-detail .sap-light-btn a {
    color: #2D2926;
    text-align: center;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    box-shadow: 6px 6px 36px 0px rgba(0, 0, 0, 0.06), -4px -4px 13px 0px #FFF, 4px 4px 7px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid #2D2926;
    max-width: 255px;
    height: 40px;
    letter-spacing: -0.3px;
    margin-bottom: 5px;
}
.brder-rt {
    border-left: 1px solid rgba(0, 0, 0, 0.10);
}
.campus-ctr-btn {
    display: flex;
    justify-content: center;
    padding: 51px 0px 20px 0px;
}

.campus-detail-con .book-icon {
    position: absolute;
    left: 0;
    animation: moveInRight 4s ease-out infinite;
    top: 50%;
}
.campus-detail-con{
    position: relative;
}
.campus-detail-con .stardetail-icon {
    position: absolute;
    top: -35px;
    right: -105px;
    animation-name: twinkle;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.campus-detail-inner .rocketdetail-icon {
    position: absolute;
    right: -22px;
    bottom: 20%;
    animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}









/*******************************************************/

@media(max-width:767px) {

.branches-page .lg-heading {
    padding: 0 0 15px 0;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
}
.branches-ban-img img {
    width: 100%;
    height: 100%;
    min-height: 115px;
}
.campus-detail-con .stardetail-icon {
    top: -52px;
    right: -16px;
}
}
/***************/

 @media (max-width:499px) {

.branches-ban-content .lg-heading {
    font-size: 21px;
    line-height: 30px;
    text-align: center;
    padding: 0;
}
.branches-ban-content {
    width: 272px;
}
.campus-detail-con .book-icon {
    top: 65%;
    left: 34px;
}

 }
/***************/

@media(min-width:768px) and (max-width:991px) {
.branches-ban-content .lg-heading {
    color: #fff;
     padding: 0;
      font-size: 31px;
}
.campus-detail-con .book-icon {
    top: 64%;
    left: -47px;
}
.campus-detail-con .stardetail-icon {
    top: -52px;
    right: -23px;
}
}



/***************/

@media(min-width:992px) and (max-width:1199px) {
.branches-ban-content .lg-heading {
    font-size: 31px;
}
.campus-detail-con .book-icon {
    top: 64%;
    left: -47px;
}
.campus-detail-con .stardetail-icon {
    top: -52px;
    right: -23px;
}
}

/***************/
@media(min-width:1200px) and (max-width:1399px) {

.campus-detail-con .book-icon {
    top: 64%;
    left: -103px;
}
.campus-detail-con .stardetail-icon {
    top: -52px;
    right: -69px;
}
}

@media  screen and (min-width: 1400px) {
.campus-detail-con .book-icon {
    left: -162px;
    top: 52%;
}
.campus-detail-con .stardetail-icon {
    top: -52px;
    right: -69px;
}
}
